@import "~@ui/styles/tools";

.container {
    padding: 8px 10px;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #1E20211A;
    display: flex;
    gap: 8px;
    align-items: center;
    width: fit-content;

    @include media-mobile {
        padding: 6px;
        height: fit-content;
    }

    &--small {
        padding: 4px;

        & .text {
            font-size: 11px;
            line-height: 130%;
        }

        & .icon {
            width: 14px;
            height: 14px;

            @include media-mobile {
                width: 12px;
                height: 12px;
            }
        }
    }
}

.text {
    color: #1E2021;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    white-space: nowrap;

    @include media-mobile {
        font-size: 11px;
        font-weight: 500;
        line-height: 140%;
    }
}

.icon {
    width: 20px;
    height: 20px;
    position: relative;

    @include media-mobile {
        width: 14px;
        height: 14px;
    }
}
