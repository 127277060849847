@import "../../styles/variables";

.container {
    width: 100%;
    height: 12px;
    border-radius: $border-radius;
    background: $color-background;
    overflow: hidden;
    margin-top: 15px;
    margin-bottom: 15px;
    position: relative;

    .support-bar {
        background: #E9E9E9;
        position: absolute;
        z-index: 0;
    }

    .bar {
        z-index: 1;
        position: relative;
    }

    .bar, .support-bar {
        height: 12px;
        left: 0;
        top: 0;
        transition: all .3s ease-in;
    }

    .label {
        position: relative;
        z-index: 2;
        width: 100%;
        text-align: center;
        font-size: $font-size--xsmall*0.7;
        line-height: 12px;
        font-weight: 400;
        transform: translateY(-100%);
    }

    .dot {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        transition: background-color .3s ease-in;
    }
}

.container--with-border {
    height: 14px;
}

.container--grey {
    background: #E9E9E9;
}

.container--default-color {
    .label {
        color: $color-title-active !important;
        font-weight: 500;
    }
}

.container--narrow {
    margin: 0;
    height: 10px;

    &.container--with-border {
        height: 11px;
    }

    .bar, .support-bar {
        height: 10px;
    }

    .label {
        font-size: $font-size--xsmall*0;
        line-height: 10px;
    }
}

.container--thin {
    margin: 0;
    height: 3px;

    &.container--with-border {
        height: 3px;
    }

    .bar, .support-bar {
        height: 3px;
    }

    .label {
        display: none;
    }
}

.container--large {
    height: 18px;

    &.container--with-border {
        height: 19px;
    }

    .bar, .support-bar {
        height: 18px;
    }

    .label {
        font-size: $font-size--xsmall;
        line-height: 18px;
    }
}

.container--small {
    height: 8px;

    &.container--with-border {
        height: 9px;
    }

    .bar, .support-bar {
        height: 8px;
    }

    .label {
        font-size: $font-size--xsmall;
        line-height: 8px;
    }
}

.container--xlarge {
    height: 30px;

    &.container--with-border {
        height: 31px;
    }

    .bar, .support-bar {
        height: 30px;
    }

    .label {
        font-size: $font-size--normal;
        line-height: 30px;
    }
}

.range-labels {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: -10px;

    > div {
        font-size: $font-size--xsmall;
        line-height: $font-size--xsmall*1.2;
        color: $color-body;
        font-weight: 600;
    }
}

@mixin buildBarColorStyles($color) {

    .container {
        background: $color;
    }

    .bar {
        background-color: $color;
    }

    .dot {
        background-color: $color;
    }

    &.container--with-border {
        border: 1px solid $color;
        background: $color
    }

    .bar--stripes {
        background: repeating-linear-gradient(
            35.78deg,
            rgba(255, 255, 255, 0.0),
            rgba(255, 255, 255, 0.0) 10px,
            rgba(255, 255, 255, 0.3) 10px,
            rgba(255, 255, 255, 0.3) 20px
          ), $color;
    }
}

.container--mobile {
    @include buildBarColorStyles($color-green);
    height: 10px;
}

.container--primary {
    @include buildBarColorStyles($color-title-active);

    // TODO Implement smart logic
    .label {
        color: #fff;
    }
}

.container--default {
    @include buildBarColorStyles($color-body);
}

.container--danger {
    @include buildBarColorStyles($color-error);
}

.container--warning {
    @include buildBarColorStyles($color-warning);
}

.container--waiting {
    @include buildBarColorStyles($color-reddish);
}

.container--success {
    @include buildBarColorStyles($color-primary);

    // TODO Implement smart logic
    // .label {
    //     color: #fff;
    // }

}

.container-gradient--colorful {
    @include buildBarColorStyles($color-gradient-colorful);

    .label {
        color: $color-off-white;
    }   
}

.container-gradient--danger {
    @include buildBarColorStyles($color-gradient-error);

    .label {
        color: $color-off-white;
    }
}

.container-gradient--warning {
    @include buildBarColorStyles($color-gradient-warning);

    .label {
        color: $color-off-white;
    }
}

.container-gradient--waiting {
    @include buildBarColorStyles($color-gradient-orange);

    .label {
        color: $color-off-white;
    }
}

.container-gradient--success {
    @include buildBarColorStyles($color-gradient-main);

    .label {
        color: $color-off-white;
    }   
}

.container-gradient--colorful {
    @include buildBarColorStyles($color-gradient-colorful);

    .label {
        color: $color-off-white;
    }   
}

.container--success-opaque {
    @include buildBarColorStyles($color-green-opaque);
}

.container--light {
    @include buildBarColorStyles($color-green-light);
}

.container--info {
    @include buildBarColorStyles($color-info);
}