@import "~@ui/styles/tools";

.container {
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: 0 -10px;
    margin-top: 30px;

    @include media-mobile {
        margin: 0px -16px;
    }
}

.item-wrapper {
    position: relative;
    margin: 0 10px;
    height: 100%;
}