@import "~@ui/styles/tools";

.container {
    border-radius: 25px;
    background-color: white;

    @include media-mobile {
        padding: 20px;
    }
}

.container--with-shadow {
    box-shadow: 0px 10px 28px 0px rgba(136, 136, 136, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}

.padding-size__normal {
    padding: 32px;
}

.padding-size__big {
    padding: 50px;
}

@include media-tablet {
    .container {
        border-radius: 12px;
    }

    .padding-size__normal {
        padding: 20px;
    }

    .padding-size__big {
        padding: 32px 20px;
    }

    .container:has(.full-size-child) {
        padding-left: 4px;
        padding-right: 4px;
    }

    .root-container {
        & > *:not(.full-size-child) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}

@include media-mobile {
    .root-container {
        & > *:not(.full-size-child) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}