@import "~@ui/styles/tools";

.container {
    background: #F6F7F8;
    height: 100px;
    min-height: 100px;
    width: 100%;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border: none;
    cursor: pointer;
    transition: 0.172s ease-in-out;

    &.disabled {
        cursor: default;
        pointer-events: none;

        height: 100%;
    }

    &.active {
        background: #1F7A66;
        cursor: default;
        pointer-events: none;
    }

    &:not(.disabled) {
        &:hover {
            background: #eeeeee;
        }
    
        &:active {
            background: #dcdcdc;
        }
    }

    @include media-tablet {
        width: 250px;
    }

    @include media-mobile {
        width: 100px;
        flex-direction: row;
        padding: 12px;
        height: 100%;
        min-height: auto;
        position: relative;
    }
}

.content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    gap: 24px;

    @include media-mobile {
        flex-direction: column;
        align-items: flex-start;
    }
}

.title {
    color: #1E2021;
    font-size: 18px;
    font-weight: 400;
    line-height: 120%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    &.active {
        color: #fff
    }

    @include media-desktop-md {
        gap: 6px;
        img {
            width: 18px;
            height: 18px;
        }
    }

    @include media-mobile {
        font-size: 14px;
        line-height: 24px;
    }
}

.estimated-time {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #1E2021;
    white-space: nowrap;

    &.active {
        color: #fff
    }

    @include media-mobile {
        font-size: 12px;
        line-height: 12px;
    }
}

.questions-count {
    color: #1E2021;
    opacity: 0.5;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

    &.active {
        color: #FFF
    }

    @include media-mobile {
        display: none;
    }
}

.icon {
    width: 20px;
    height: 20px;
    position: relative;

    @include media-mobile {
        position: absolute;
        right: 12px;
        bottom: 12px;
    }
}
