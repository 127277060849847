@import "~@ui/styles/tools";
@import  "../../../../../styles/variables.scss";

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1250px) {
        @content;
    }
}

@mixin card-styles() {
    background: #F6F7F8;
    color: $color-title-lighter;
    cursor: pointer;
    height: 100%;
    transition: all 0.5s;

    &:hover {
        box-shadow: 0px 10px 28px 0px rgba(136, 136, 136, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);
    }
}

@mixin card-date-styles() {
    color: #A2A8AD;
    font-weight: 700;
    margin-bottom: 0;
}

.card-link {
    text-decoration: none !important;
}

.card {
    @include card-styles();

    display: flex;
    flex-direction: column;
}

.card--white {
    background: #fff;
}

.card__image-wrapper {
    position: relative;
    width: 100%;
    display: grid;
    aspect-ratio: 16/9;

    margin-bottom: 25px;

    // Override NextJS img styles to keep correct aspect ratio for image
    img {        
        object-fit: cover;
    }
} 

.card__image {
    border-radius: 12px;
}

.card__text {
    flex: 1;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1450px) {
        max-width: initial;
        height: 175px;
    }
}

.card__text-title {
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 16px;

    @include medium-breakpoint {
        font-size: 22px;
        line-height: 1.1;
    }
}

.card__text-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: 0.3px;
    color: #1E202180;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;

    @include medium-breakpoint {
        font-size: 14px;
    }

    @include media-mobile {
        font-size: 14px
    }
}

.card__text-date {
    @include card-date-styles();

    margin-top: auto;
}

.badges {
    display: flex;  
    gap: 6px;
    margin-bottom: 15px;
}


.progress-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: -5px;

    & span:first-child {
        font-size: 16px;
        font-weight: 500;
        line-height: 25.6px;
        color: #A2A8AD;

        @include media-mobile {
            font-size: 12px
        }
    }

    & span:last-child {
        font-size: 16px;
        font-weight: 400;
        line-height: 25.6px;
        color: #1E2021;

        @include media-mobile {
            font-size: 12px
        }
    }
}

.passed-block {
    font-size: 16px;
    font-weight: 600;
    line-height: 25.6px;
    color: #20836D;
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.link {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 25.6px;
    display: block;
    margin-top: -5px;

    @include media-mobile {
        font-size: 12px
    }
}

.estimated-container {
    margin-top: 30px;

    & div:first-child {
        margin-bottom: 12px;
    }

    .estimated-block {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & span:first-child {
            font-size: 16px;
            font-weight: 600;
            line-height: 25.6px;
            color: #20836D;
        }

        & span:last-child {
            font-size: 14px;
            font-weight: 700;
            line-height: 22.4px;
            color: #A2A8AD;
        }
    }
}