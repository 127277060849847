@import '~@ui/styles/tools';

.container {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    gap: 10px;
    text-decoration: none !important;
}

.container:hover > .icon{
    background: rgba(0, 0, 0, 0.05);
}

.icon {
    position: relative;
    transition: all 0.172s ease-in-out;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    &>img {
        padding: 5px 5px 5px 3px;
    }
}

.text {
    font-size: 18px;
    color: #c9cdce;
    text-decoration: none;
}

@include media-mobile {
    .icon {
        width: 24px;
        height: 24px;
    }

    .text {
        font-size: 16px;
    }
}