@import "~@ui/styles/tools";

@mixin medium-breakpoint {
    @media screen and (max-width: 1400px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 900px) {
        @content;
    }
}

.embed-video {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;

    iframe {
        display: grid;
        width: 100%;
        aspect-ratio: calc(560.0 / 315.0);
        border-radius: 25px;

        @include media-mobile {
            border-radius: 12px;
        }
    }
}

.mainContainer {
    padding: 0 188px 110px;
    margin-top: 32px;

    @include medium-breakpoint {
        padding: 0 60px 110px;
    }

    @include media-tablet {
        padding: 0 60px 60px;
    }

    @include media-mobile {
        padding: 0 16px 48px;
    }
}

.description {
    position: relative;
    overflow: hidden;
}

.apply {
    margin-top: 20px;
    display: none;

    @include media-tablet {
        margin-top: 12px;
    }

    @include small-breakpoint {
        display: block;
    }
}

.buttonContainer {
    margin: 0;
    padding: 0;
    border: none; 
    margin-top: 20px;

    @include media-tablet {
        margin-top: 12px;
    }
}