@import "~@ui/styles/tools";

.container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.text-container {
    display: flex;
    justify-content: space-between;
    color: #A2A8AD;
    font-size: 16px;
    font-weight: 500;
    line-height: 160%;

    @include media-mobile {
        font-size: 12px;
    }
}
