@import '~@ui/styles/tools';
@import '~@ui/styles/variables';

@mixin xsmall-breakpoint {
    @media screen and (max-width: 1100px) {
        @content;
    }
}

.container-top {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    grid-auto-rows: 1fr;
    grid-template-areas: 
        "card card image";
}

.image-wrapper {
    grid-area: image;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 24px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 350px;
}

.title {
    font-size: 40px;
    font-weight: 600;
    line-height: 47.73px;
    color: #fff;
}

.link {
    font-size: 20px;
    font-weight: 400;
    line-height: 23.87px;
    text-align: left;
    background: #fff;
    color: #1E2021 !important;
    border-radius: 30px;
    height: 56px;
    padding: 0 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    gap: 10px;
    transition: 0.5s ease;

    & img {
        transition: 0.5s ease;
    }

    &:hover {
        cursor: pointer;
        color: #fff !important;
        transition: 0.5s ease;
        text-decoration: none !important;
        background: #1a9171;

        & img {
            filter: invert(1);
            transition: 0.5s ease;
        }
    }
}

.empty-text {
    font-size: 20px;
    color: #c9cdce;
    text-decoration: none;
}

.action {
    font-size: 24px;
    font-weight: 600;
    line-height: 28.64px;
    vertical-align: text-top;
}

@include xsmall-breakpoint {
    .container-top {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: 
            "card image";
    }

    .title {
       font-size: 36px;
        line-height: 42px; 
    }

    .link {
        font-size: 16px;
        height: 45px;
    }
}

@include media-tablet-portrait {
    .image-wrapper {
        min-height: 366px;
    }

    .title {
        font-size: 25px;
        line-height: 36px;
    }
}

@include media-mobile {
    .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 28.64px;
    }

    .container-top {
        display: flex;
        flex-direction: column;
    }

    .link {
        font-size: 14px;
        font-weight: 400;
        line-height: 19.09px;
        padding: 0 14px;
        height: 48px;
    }
}