@import "~@ui/styles/tools";

.container {
    display: flex;
    flex-direction: column;
    // height: 532px;
    max-height: 532px;
    overflow: auto;
    gap: 8px;
    direction: rtl;
    padding-left: 8px;

    &::-webkit-scrollbar {
        width: 2px;
    }

    &::-webkit-scrollbar-track {
        background: #F6F7F8;
    }

    &::-webkit-scrollbar-thumb {
        background: #1F7A66;
    }

    & > * {
        direction: ltr;
    }

    @include media-tablet {
        display: grid;
        grid-template-rows: max-content;
        grid-auto-flow: column;
        flex-direction: row;
        overflow-x: auto;
        overflow-y: hidden;
        direction: ltr;
        height: auto;
        padding-left: 0;
        padding-bottom: 8px;

        &::-webkit-scrollbar {
            height: 2px;
        }
    }
}
