@import "~@ui/styles/tools";

@mixin medium-breakpoint {
    @media screen and (max-width: 1500px) {
        @content;
    }
}

.container {
    width: 100%;
    background: #fff;
    padding: 8px 0;
    border-radius: 12px;
    border: 1px solid #E9E9E9;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        filter: brightness(0.95);
    }

    &:active {
        filter: brightness(0.9);
    }

    &:disabled {
        pointer-events: none;
        cursor: default;
    }

    &.active {
        background: #1F7A66;
    }
}

.loader {
    height: 49px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
}

.title {
    color: #1E2021;
    font-size: 16px;
    font-weight: 600;
    line-height: 160%;

    &.active {
        color: #fff;
    }

    @include medium-breakpoint {
        font-size: 15px;
    }

    @include media-mobile {
        font-size: 14px;
    }
}

.description {
    color: #20836D;
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;

    &.active {
        color: #fff;
    }

    @include medium-breakpoint {
        font-size: 13px;
    }

    @include media-mobile {
        font-size: 12px;
    }
}
